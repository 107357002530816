import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { WebsitesService } from '../services/websites.service';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css']
})
export class WebsiteComponent implements OnInit {

  domain: string = '';
  heading: string = ''
  subheading: string = '';
  color: string = '#ffffff';
  mainImage: string = '';
  backgroundImage: string = '';
  // phone: string = '';
  email: string = '';
  features: any[] = [];

  constructor(
    private websiteService: WebsitesService,
    private router: Router, private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.domain = this.route.snapshot.paramMap.get('domain') ?? '';

    try {
      var websiteData = await this.websiteService.getWebsite(this.domain).toPromise()
      console.log(websiteData);
      this.heading = websiteData.website.heading;
      this.subheading = websiteData.website.subheading;
      this.color = websiteData.website.color;
      // this.phone = websiteData.website.phone;
      this.email = websiteData.website.email;
      this.features = websiteData.website.features;
      this.mainImage = websiteData.website.mainImage ?? 'https://storage.googleapis.com/invoicing-247-sites/defaults/default-banner-0.jpeg';
      this.backgroundImage = websiteData.website.backgroundImage ?? 'https://storage.googleapis.com/invoicing-247-sites/defaults/default-background-0.png';
      console.log(this.features);

      document.documentElement.style.setProperty('--main-color-hex', this.color);
      document.documentElement.style.setProperty('--banner-image', 'url(' + this.mainImage + ')');
      document.documentElement.style.setProperty('--background-image', 'url(' + this.backgroundImage + ')');
    } catch (error) {
      this.router.navigate(['/notfound']);
    }
  }

}
