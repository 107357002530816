<!-- Wrapper -->
<div id="wrapper">

    <!-- Header -->
    <header id="header" class="alt">

    </header>

    <!-- Banner -->
    <section id="banner">
        <div class="content">
            <h1>{{heading}}</h1>
            <p>{{subheading}}</p>
        </div>
        <!-- <a href="#first" class="more scrolly">Learn More</a> -->
    </section>

    <!-- Section -->
    <section class="main alt" id="first">
        <!-- <header>
                <h2>Fusce sed adipiscing</h2>
                <p>Aenean ornare velit lacus varius enim ullamcorper proin aliquam facilisis ante sed etiam magna interdum congue. Sed consequat amet dolor magna consequat. Lorem ipsum dolor amet nullam sed etiam veroeros.</p>
            </header> -->
        <div class="inner">
            <article *ngFor="let feature of features; let odd = odd" [class]="odd ? 'post style2 alt' : 'post style2'">
                <div class="content">
                    <header>
                        <!-- <span class="category">Erat lacinia</span> -->
                        <h3 class="category">{{feature.title}}</h3>
                    </header>
                    <p>{{feature.description}}</p>
                    <!-- <ul class="actions">
                            <li><a href="#" class="button next">Learn More</a></li>
                        </ul> -->
                </div>
                <div class="image" data-position="center"><img [src]="feature.image" alt="" /></div>
            </article>
        </div>
    </section>

    <!-- Footer -->
    <footer id="footer">
        <ul class="icons">
            <!-- <li><a href="#" class="icon alt fa-twitter"><span class="label">Twitter</span></a></li>
                <li><a href="#" class="icon alt fa-facebook"><span class="label">Facebook</span></a></li>
                <li><a href="#" class="icon alt fa-instagram"><span class="label">Instagram</span></a></li>
                <li *ngIf="phone != null && phone.trim().length > 0"><a [href]="phone" class="icon alt fa-phone"><span class="label">Phone</span></a></li> -->
            <li *ngIf="email != null && email.trim().length > 0"><a [href]="'mailto:' + email"
                    class="icon alt fa-envelope-o"><span class="label">Email</span></a></li>
        </ul>
        <p class="copyright">Powered by <a href="https://invoicing247.com">Invoicing 24/7</a></p>
    </footer>

</div>

<!-- Scripts -->
<script src="../../assets/js/jquery.min.js"></script>
<script src="../../assets/js/jquery.dropotron.min.js"></script>
<script src="../../assets/js/jquery.scrollex.min.js"></script>
<script src="../../assets/js/jquery.scrolly.min.js"></script>
<script src="../../assets/js/browser.min.js"></script>
<script src="../../assets/js/breakpoints.min.js"></script>
<script src="../../assets/js/util.js"></script>
<script src="../../assets/js/main.js"></script>