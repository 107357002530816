<!-- Wrapper -->
<div id="wrapper">

    <!-- Header -->
        <header id="header" class="alt">

        </header>

    <!-- Banner -->
        <section id="banner">
            <div class="content">
                <h1>NOT FOUND</h1>
                <p>The page you are looking for does not exist, or has been removed.</p>
            </div>
            <!-- <a href="#first" class="more scrolly">Learn More</a> -->
        </section>

    <!-- Footer -->
        <footer id="footer">
            <ul class="icons">
                <li><a href="https://twitter.com/invoicing247" class="icon alt fa-twitter"><span class="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/invoicing247" class="icon alt fa-facebook"><span class="label">Facebook</span></a></li>
                <li><a href="mailto://info@invoicing247.com" class="icon alt fa-envelope-o"><span class="label">Email</span></a></li>
            </ul>
            <p class="copyright">&copy; Invoicing 24/7. All rights reserved.</p>
        </footer>

</div>

<!-- Scripts -->
<script src="../../assets/js/jquery.min.js"></script>
<script src="../../assets/js/jquery.dropotron.min.js"></script>
<script src="../../assets/js/jquery.scrollex.min.js"></script>
<script src="../../assets/js/jquery.scrolly.min.js"></script>
<script src="../../assets/js/browser.min.js"></script>
<script src="../../assets/js/breakpoints.min.js"></script>
<script src="../../assets/js/util.js"></script>
<script src="../../assets/js/main.js"></script>